import request from '@/config/request.js'
const ApiOrder = request.Order
const ApiTeacherAssessment = request.teacherAssessment
export default {
  name: 'order-management',
  data () {
    // const changeStudent = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请选择学生'))
    //   } else if (this.orderDetails.zoneStudent.studentId === '') {
    //     callback(new Error('请选择学生!'))
    //   } else {
    //     callback()
    //   }
    // }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码！'))
      } else {
        var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
    }
    const checkMoney = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入缴费金额'))
      } else {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的缴费金额'))
        } else {
          callback()
        }
      }
    }
    const checkServer = (rule, value, callback) => {
      if (this.orderDetails.servicesArr.length === 0 && this.orderDetails.serviceNote === '') {
        callback(new Error('请至少选择一种服务'))
      } else {
        callback()
      }
    }
    const checkTime = (rule, value, callback) => {
      if (this.orderDetails.serviceStart === '' || this.orderDetails.serviceEnd === '') {
        callback(new Error('请选择服务起止时间'))
      } else {
        let end = this.orderDetails.serviceEnd
        let str = this.orderDetails.serviceStart
        console.log(end, str)
        end = Date.parse(end)
        str = new Date(str)
        console.log(end, str)
        const str1 = str.getTime()
        if (end > str.setMonth(str.getMonth() + 6)) {
          callback(new Error('单笔订单服务时间最多不超过六个月'))
        } else if (str1 > end) {
          callback(new Error('开始时间不能大于结束时间'))
        } else {
          callback()
        }
      }
    }
    return {
      loadtext: '正在加载中',
      search_data: {
        filters: [{
          field: 'orderCode',
          operator: 'like',
          value: ''
        }, {
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'services',
          operator: 'like',
          value: ''
        }, {
          field: 'orderStatus',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      services: [
        {
          label: '午托',
          value: 'MORNING'
        }, {
          label: '下午托',
          value: 'AFTERNOON'
        }, {
          label: '作业托',
          value: 'WORK'
        }, {
          label: '其它',
          value: 'OTHER'
        }
      ],
      orderStatus: [
        {
          label: '正常',
          value: 1
        }, {
          label: '即将过期',
          value: 2
        }, {
          label: '已到期',
          value: 3
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: ''
      },
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      },
      details: {
        zoneStudent: {
          student: {
            studentName: ''
          }
        },
        zoneTeacher: {
          teacher: {
            teacherName: ''
          }
        },
        studyClass: {
          studyClassName: ''
        }
      },
      DetailFormVisible: false,
      orderDetails: {
        orderId: '',
        zoneStudent: {
          studentId: ''
        },
        studyClass: {
          studyClassId: ''
        },
        mobile: '',
        total: '',
        services: '',
        serviceNote: '',
        serviceStart: '',
        serviceEnd: '',
        zoneTeacher: {
          teacherId: ''
        },
        servicesArr: [],
        note: ''
      },
      orderRules: {
        'studyClass.studyClassId': [
          { required: true, message: '请选择所在班级', trigger: 'change' }
        ],
        'zoneStudent.studentId': [
          { required: true, message: '请选择开通服务的学生', trigger: 'change' }
        ],
        mobile: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        total: [
          { required: true, validator: checkMoney, trigger: 'blur' }
        ],
        changeServices: [
          { required: true, validator: checkServer, trigger: 'blur' }
        ],
        changeData: [
          { required: true, validator: checkTime, trigger: 'change' }
        ],
        'zoneTeacher.teacherId': [
          { required: true, message: '请选择经办人', trigger: 'change' }
        ],
        note: [
          { required: false, message: '请输入需要备注的内容', trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      edit: 'add',
      studyClass: [],
      zoneStudent: [],
      zoneTeacher: [],
      pickerOptions: {
        disabledDate (time) {
          const nowdate = new Date()
          return time.getTime() > nowdate.setMonth(nowdate.getMonth() + 3)
        }
      },
      DetailVisible: false,
      PrintVisible: false,
      printMe: {
        id: 'printMe',
        popTitle: '订单回执打印',
        extraClass: './index.less/index.css',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printDetails: {
        zoneStudent: {
          student: {
            studentName: ''
          }
        },
        studyClass: {
          studyClassName: ''
        },
        zoneTeacher: {
          teacher: {
            teacherName: ''
          }
        }
      },
      moneyCheck: {
        0: '零',
        1: '壹',
        2: '贰',
        3: '叁',
        4: '肆',
        5: '伍',
        6: '陆',
        7: '柒',
        8: '捌',
        9: '玖'
      },
      userInfo: JSON.parse(this.$store.state.login.userInfo),
      bgUrl: require('../../../../assets/print/logo.png')
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    /*
    * 获取当前系统时间
    * */
    getNowTime () {
      var now = new Date()
      var year = now.getFullYear()
      var month = now.getMonth()
      var date = now.getDate()
      var Hours = now.getHours()
      var Minutes = now.getMinutes()
      var Seconds = now.getSeconds()
      month = month + 1
      if (month < 10) month = '0' + month
      if (date < 10) date = '0' + date
      if (Hours < 10) Hours = '0' + Hours
      if (Minutes < 10) Minutes = '0' + Minutes
      if (Seconds < 10) Seconds = '0' + Seconds
      return year + '-' + month + '-' + date + ' ' + Hours + ':' + Minutes + ':' + Seconds
    },
    /*
    * 小写转换
    * */
    getMoneyBig (num) {
      return this.moneyCheck[num]
    },
    getStringSub (str, bl, index) {
      if (str) {
        str = str + ''
        const arr = str.split('.')
        if (bl > 0) {
          if (index >= arr[0].length) {
            return '\\'
          } else {
            return this.getMoneyBig(arr[0][arr[0].length - index - 1])
          }
        } else {
          if (arr.length > 1) {
            if (index >= arr[1].length) {
              return '零'
            } else {
              return this.getMoneyBig(arr[1][index])
            }
          } else {
            return '零'
          }
        }
      }
    },
    /*
    * onPrint 打印
    * */
    onPrint () {
      // this.$print(this.$refs.printMe)
      const tabStyle = `<style>
          #printMe{
            color: #999;
          }
          .p-title{
          display: flex;
          justify-content: center;
          font-size: 30px;
          font-family: 新宋体;
          font-weight: bolder;
          letter-spacing: 8px;
          text-align: center;
          }
          table {
            border-top: 1px solid #999;
            border-left: 1px solid #999;
            border-spacing: 0;
            flex: 1;
          }
          tr{
            height: 40px;
          }
          th{
           word-break: break-all;
            padding: 10px;
            border-bottom: 1px solid #999;
            border-right: 1px solid #999;
            height: 50px;
            font-size: 16px;
            text-align: center;
          }
          td {
            word-break: break-all;
            padding: 10px;
            border-bottom: 1px solid #999;
            border-right: 1px solid #999;
            /*min-width: 58px;*/
            height: 17px;
            font-size: 13px;
            text-align: center;
          }
           td .s-text{
            display: inline-block;
            width: 28px;
            text-align: center;
            font-size: 16px;
            }
           td .s-text.s-end{
              width: 70px;
            }
            td.beizhu{
            height: 120px;
            }
          </style><body>`
      // 表单数据
      // var office = document.getElementsByClassName('office')[0]
      // var img1 = require('../../../../assets/classImg/d6501.png')
      // // office.setAttribute('style', 'background: url(' + img1 + ')')
      // console.log(office, '123', img1)
      // var imgs = office.getElementsByTagName('img')
      // console.log(imgs, '456', !imgs.length)
      // var img, src
      // if (!imgs.length) {
      //   src = img1
      //   img = document.createElement('img')
      //   img.src = src
      //   img.height = 150
      //   img.width = 150
      //   // office.style.backgroundImage = 'none'
      //   office.appendChild(img)
      // }
      this.getBase64(require('../../../../assets/print/logo.png'), list => {
        // var imgs = document.getElementsByClassName('imgBg')[0]
        // imgs.setAttribute('src', list)
        // imgs.setAttribute('style', 'display: block;position: absolute;top: 5px;left: 0;width: 180px')
        this.$nextTick(() => {
          const printForm = this.$refs.printMe.innerHTML
          const printStr = "<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'></head>"
          const printPart1 = printStr + tabStyle + '</body></html>'
          const printPart = printPart1 + printForm
          const newTab = window.open('_blank')
          // console.log(printPart, this.bgUrl, require('../../../../assets/print/logo.png'))
          newTab.document.body.innerHTML = printPart
          newTab.print()
          newTab.close()
        })
        // console.log(imgs)
      })
    },
    getBase64 (url, callback) {
      const Img = new Image()
      let dataURL = ''
      Img.src = url + '?v=' + Math.random()
      Img.setAttribute('crossOrigin', 'Anonymous') // 解决控制台跨域报错的问题（没用就跟后台沟通添加跨域）
      Img.onload = function () { // 要先确保图片完整获取到，这是个异步事件
        const canvas = document.createElement('canvas') // 创建canvas元素
        const width = Img.width // 确保canvas的尺寸和图片一样
        const height = Img.height
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height) // 将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/' + url) // 转换图片为dataURL
        callback(dataURL)
      }
    },
    /*
     * 打印预览
     * */
    onPrintShow (row) {
      this.printDetails = {
        zoneStudent: {
          student: {
            studentName: ''
          }
        },
        studyClass: {
          studyClassName: ''
        },
        zoneTeacher: {
          teacher: {
            teacherName: ''
          }
        }
      }
      this.printDetails = Object.assign({}, row)
      this.PrintVisible = true
    },
    /*
    * 发送短信提醒
    * */
    expirationReminder (id) {
      ApiOrder.expirationReminder.call(this, { orderId: id }, (item) => {
      })
    },
    getDetails (item) {
      this.DetailVisible = true
      this.details = Object.assign(this.details, item)
    },
    getRowDetails (item) {
      const row = Object.assign(this.orderDetails, item)
      this.edit = 'edit'
      this.getStudyClassByZoneId(item.studyClass.studyClassId)
      this.getzoneTeacher()
      if (row.services !== '') {
        const strArr = []
        row.services = row.services.split(',')
        for (let j = 0; j < row.services.length; j++) {
          for (let i = 0; i < this.services.length; i++) {
            if (row.services[j] === this.services[i].value) {
              strArr.push(this.services[i].label)
            }
          }
        }
        row.servicesArr = strArr
        row.services = ''
      }
      this.DetailFormVisible = true
      this.orderDetails = Object.assign(this.orderDetails, row)
    },
    getzoneTeacher () {
      ApiTeacherAssessment.getZoneTeachers.call(this, {}, (item) => {
        this.zoneTeacher = item
      })
    },
    getStudyClassByZoneId (id) {
      ApiOrder.getStudyClassByZoneId.call(this, {}, (listData) => {
        this.studyClass = listData
        if (id) {
          this.studyClass.map(item => {
            if (item.studyClassId === this.orderDetails.studyClass.studyClassId) {
              if (item.studyClassZoneStudents && item.studyClassZoneStudents.length > 0) {
                this.zoneStudent = item.studyClassZoneStudents
              }
            }
          })
        }
      })
    },
    getStudent () {
      this.orderDetails.zoneStudent.studentId = ''
      this.zoneStudent = []
      this.studyClass.map(item => {
        console.log(item, 'item')
        if (item.studyClassId === this.orderDetails.studyClass.studyClassId) {
          if (item.studyClassZoneStudents && item.studyClassZoneStudents.length > 0) {
            this.zoneStudent = item.studyClassZoneStudents
          }
        }
      })
    },
    addShow (type) {
      this.orderDetails = {
        orderId: '',
        zoneStudent: {
          studentId: ''
        },
        studyClass: {
          studyClassId: ''
        },
        mobile: '',
        total: '',
        services: '',
        serviceNote: '',
        serviceStart: '',
        serviceEnd: '',
        zoneTeacher: {
          teacherId: ''
        },
        servicesArr: [],
        note: ''
      }
      this.edit = type
      this.getStudyClassByZoneId()
      this.getzoneTeacher()
      this.$nextTick(() => {
        this.DetailFormVisible = true
      })
    },
    onDeleteOrder (id) {
      const that = this
      this.$confirm('是否确定删除该订单？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiOrder.deleteStudentOrder.call(this, { orderId: id }, () => {
          that.getList()
        })
      }).catch(() => {
      })
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const strArr = []
          if (that.orderDetails.servicesArr.length > 0) {
            for (let j = 0; j < that.orderDetails.servicesArr.length; j++) {
              for (let i = 0; i < that.services.length; i++) {
                if (that.orderDetails.servicesArr[j] === that.services[i].label) {
                  strArr.push(that.services[i].value)
                }
              }
            }
          }
          that.orderDetails.services = strArr.join(',')
          let str = 'add'
          str = this.edit === 'add' ? 'addStudentOrder' : 'updateStudentOrder'
          ApiOrder[str].call(this, that.orderDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            this.getList()
            this.$refs[formName].resetFields()
            this.DetailFormVisible = false
          })
        }
      })
    },
    // 取消订单新增编辑界面
    resetStudent (formName) {
      this.$refs[formName].resetFields()
      this.DetailFormVisible = false
    },
    // 关闭订单新增编辑界面
    closeOrder (formName) {
      this.orderDetails = {
        orderId: '',
        zoneStudent: {
          studentId: ''
        },
        studyClass: {
          studyClassId: ''
        },
        mobile: '',
        total: '',
        services: '',
        serviceNote: '',
        serviceStart: '',
        serviceEnd: '',
        zoneTeacher: {
          teacherId: ''
        },
        servicesArr: [],
        note: ''
      }
      // this.$refs[formName].resetFields()
      this.DetailFormVisible = false
    },
    onResetSearch () {
      this.search_data = {
        filters: [{
          field: 'orderCode',
          operator: 'like',
          value: ''
        }, {
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'services',
          operator: 'like',
          value: ''
        }, {
          field: 'orderStatus',
          operator: 'eq',
          value: ''
        }]
      }
      this.getList()
    },
    getorderStatus (str) {
      for (let j = 0; j < this.orderStatus.length; j++) {
        if (this.orderStatus[j].value === str) {
          return this.orderStatus[j].label
        }
      }
    },
    getServices (str) {
      if (str && str.split(',').length > 0) {
        let item = ''
        const obj = str.split(',')
        for (let i = 0; i < obj.length; i++) {
          for (let j = 0; j < this.services.length; j++) {
            if (obj[i] === this.services[j].value) {
              item += this.services[j].label + ' '
            }
          }
        }
        return item
      } else {
        return ''
      }
    },
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiOrder.exportStudentOrder.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    getDetail (row) {
      this.DetailFormVisible = true
      this.dateDetail = row
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {}
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiOrder.getStudentOrder.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
